.table {
  background-color: $color-white;

  >:not(:last-child)>:last-child>*,
  >:not(caption)>*>* {
    border-bottom: 6px solid $color-border;
  }

  th {
    background-color: rgba($color-sc-light-blue, .2);
    color: $color-sc-light-blue;
    font-weight: normal;
  }
}
