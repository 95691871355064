.loading-wrap {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.75);
  color: $color-white;

  img {
    width: 100px;
    margin: 0;
  }

  p {
    margin-top: -25px;
    padding: 0;
    color: $color-sc-light-blue;
  }
}
