.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 20px;
  overflow: hidden;
  overflow-y: auto;
  background-image: url("../images/sc-main-bg.png");
  background-size: cover;

  .logo {
    display: block;
    width: 120px;
    margin: 0 auto 10px;
  }

  .title {
    font-size: 1.5em;
    color: "#000000";
  }
}

.login-form {
  width: 100%;
  max-width: 420px;
  padding: 30px 50px 50px;
  background-color: $color-white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.login-input.form-control {
  width: 100%;
}

.side-link {
  width: 80%;
  margin-bottom: 8px;
  cursor: pointer;
}

.form-control.search-filter {
  width: 250px;
}

.scv-input-focus {
  &:focus {
    border-color: $color-sc-red;
    box-shadow: 0 0 0 0.25rem rgba(229, 16, 34, 0.25);
  }
}
