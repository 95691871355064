@font-face {
  font-family: "Gotham";
  src: local("Gotham") url("./fonts/Gotham-Black.otf") format("opentype");
}

@font-face {
  font-family: "Gotham";
  font-weight: bold;
  src: local("Gotham") url("./fonts/Gotham-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham") url("./fonts/Gotham-Book.otf") format("opentype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham") url("./fonts/Gotham-Medium.otf") format("opentype");
}

html,
body {
  font-family: "Gotham", Helvetica, Arial, sans-serif;
  font-size: 15px;
}

a {
  color: $color-sc-light-blue;
}
