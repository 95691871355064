.dashboard-wrap {
  display: block;
  height: 100vh;

  .navbar {
    height: $navbar-height;
    padding: 0;
    background-color: $color-sc-dark-blue;

    .navbar-brand {
      img {
        height: 45px;
        margin: 5px 0;
      }
    }

    .navbar-text {
      color: $color-white;
    }
  }

}


.dashboard-body {
  display: flex;
  height: calc(100vh - #{$navbar-height});
}

.sidebar {
  width: $sidebar-width;
  height: 100%;
  background: $color-sidebar;

  .side-link {
    width: 100%;
    text-align: left;
    margin: 0;

    a {
      padding: 15px;
      border-radius: 0;
      color: $color-sc-dark-blue;
      border-bottom: 1px solid rgba(0,0,0,.05);
      
      &:hover {
        color: $color-sc-light-blue;
      }
    
      &.active {
        background-color: $color-sc-light-blue;
        color: $color-white;
      }
    }
  }
}

.dash-inner {
  flex: 1;
  height: 100%;
  background: $color-body;

  .section-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;    
    text-align: left;
    border-bottom: 1px solid rgba(0,0,0,.1);

    h3 {
      margin: 0;
      line-height: 32px;
    }
  }

  .section-body {
    padding: 20px;    
    text-align: left;
    height: calc(100vh - 120px);
    overflow: hidden;
    overflow-y: auto;

    .search-header {

      display: flex; justify-content: space-between; margin-bottom: 8px;
      
      .form-select {
        width: 75px;      
      }
    }
  }
}


.page-item {
  .page-link {
    padding: 0 0.5rem;
    border: 1px solid  $color-sc-light-blue;
    color: $color-sc-light-blue;
  }

  &.active {
    .page-link {
      color: $color-white;
      background-color: $color-sc-light-blue;
      border-color: $color-sc-light-blue;
    }
  }
}
