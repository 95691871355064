.btn-primary {
  background-color: $color-sc-light-blue;
  border-color: $color-sc-light-blue;

  &:hover,
  &:focus {
    background-color: darken($color-sc-light-blue, 10%);
    border-color: darken($color-sc-light-blue, 10%);
  }
}

.sort-arrow-up {
  border: none;
  border-width: 0px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $color-sc-light-blue;
  display: inline-flex;
  margin-left: 2px;
  cursor: pointer;
}

.sort-arrow-down {
  border: none;
  border-width: 0px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid $color-sc-light-blue;
  display: inline-flex;
  margin-left: 2px;
  cursor: pointer;
}

.unsorted {
  border: none;
  border-width: 0px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 2px solid $color-sc-light-blue;
  display: inline-flex;
  margin-left: 2px;
  cursor: pointer;
}
